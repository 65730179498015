import CSS_VARIABLES from '../../../../../resources/css/_variables.scss'
import I18n from '../../../../../utils/i18n/I18n'
import {DATA_QUALITY_KEYS, DISTRICT_COOLING, DISTRICT_HEATING, LANDLORD, MENU_ITEM_ALL, PERIOD_TYPES, TENANT,} from '../../../../../utils/helpers/Constants'
import {getInitialPickerValue, getInitialTimePeriod, periodOptions} from '../../../../../services/common/datepicker-service'
import {datePickerQuery} from '../../../../../utils/query-params/QueryParams'
import {createEndMonthParam, getLocalizedValue} from '../../../../../utils/helpers/Helper'
import {Icon, Text} from '../../../../atomic'
import {formatNumWOneDecimal} from '../../../../../services/common/formatter'
import { isEmptyObject } from 'utils/helpers/Utils'

export const utilityListsDefault={label: I18n('t_alu'), value: MENU_ITEM_ALL}
export const areaListsDefault={label: I18n('t_aas'), value: MENU_ITEM_ALL}

export const utilityListV2 = [
  {label: I18n('t_alu'), value: MENU_ITEM_ALL},
  {label: I18n('t_electricity'), value: 'Electricity'},
  {label: I18n('t_gas'), value: 'Gas'},
  {label: I18n('t_water'), value: 'Water'},
  {label: I18n('t_oil'), value: 'Oil'},
  {label: I18n('t_districtcooling'), value: DISTRICT_COOLING},
  {label: I18n('t_districtheating'), value: DISTRICT_HEATING}
]

export const areaListV2 = [
  {label: I18n('t_aas'), value: MENU_ITEM_ALL},
  {label: I18n('t_commonareas'), value: 'CommonAreas'},
  // {label: I18n('t_outdoorexteriorareaparking'), value: 'OutdoorExteriorAreaParking'}, // Not in use at the moment, but can be added later.
  {label: I18n('t_sharedservices'), value: 'SharedServices'},
  {label: I18n('t_tenantspace'), value: 'TenantSpace'},
  {label: I18n('t_wholebuilding'), value: 'WholeBuilding'}
]
export const categoriesListsDefault={label: I18n('t_all_categories'), value: MENU_ITEM_ALL}

export const CATEGORIES_LIST = [
  {label: I18n('t_all_categories'), value: MENU_ITEM_ALL},
  {label: I18n('t_actual'), value: DATA_QUALITY_KEYS?.ACTUAL},
  {label: I18n('t_estimated'), value: DATA_QUALITY_KEYS?.ESTIMATE},
  {label: I18n('t_incomplete'), value: DATA_QUALITY_KEYS?.INCOMPLETE}
]

export const CATEGORIES_LIST_V2 = [
  {label: I18n('t_all_categories'), value: MENU_ITEM_ALL},
  {label: I18n('t_actual_data'), value: DATA_QUALITY_KEYS?.ACTUAL},
  {label: I18n('t_supplier_estimate'), value: DATA_QUALITY_KEYS?.SUPPLIER_ESTIMATE},
  {label: I18n('t_calculated_estimate'), value: DATA_QUALITY_KEYS?.CALCULATED_ESTIMATE},
  {label: I18n('t_missing_tc'), value: DATA_QUALITY_KEYS?.MISSING}
]

const isAreaAllSelected = (newArea)=> newArea === MENU_ITEM_ALL

const isUtilAllSelected = (newUtil)=> newUtil === MENU_ITEM_ALL

const isCategoryAllSelected = (newCategory)=> newCategory === MENU_ITEM_ALL

export function isOverviewGraphDataLoading(overViewGraphData){
  return overViewGraphData?.isLoading
}

export function isMissingDataLoading(missingData){
  return missingData?.isLoading
}

export function getDataGapLandlord(missingData) {
  if(!isMissingDataLoading(missingData) && missingData?.data) {
    return missingData.data.assetMeterDataQualitySummaries.filter(meterData => LANDLORD === meterData.responsibility)
  }
}

export function getDataGapTenant(missingData){
  if(!isMissingDataLoading(missingData) && missingData?.data) {
    return missingData.data.assetMeterDataQualitySummaries.filter(meterData => TENANT === meterData.responsibility)
  }
}

export function resetExpandGraphBarConfigObject(updateDataQualityHomeState) {
  updateDataQualityHomeState({
    expandGraphBarConfigLandlord: null,
    expandGraphBarConfigTenant: null,
  })
}

export function updateExpandGraphBarConfigObject(expandGraphBarConfig, owner, updateDataQualityHomeState) {
  if(owner===LANDLORD){
    updateDataQualityHomeState({expandGraphBarConfigLandlord: expandGraphBarConfig})
  } else {
    updateDataQualityHomeState({expandGraphBarConfigTenant: expandGraphBarConfig})
  }
}

export function filterMeterDataGaps({filterEstimate, filterSelectedUtil, filterSelectedArea, meterDataGaps, searchText = ''}) {
  filterEstimate = filterEstimate == DATA_QUALITY_KEYS.INCOMPLETE ? DATA_QUALITY_KEYS.MISSING : filterEstimate
  let filteredMeterData
  if (meterDataGaps?.length > 0) {
    filteredMeterData=meterDataGaps.filter(
      dataGap => (isCategoryAllSelected(filterEstimate) ||
           dataGap.estimate.toLowerCase() ===  filterEstimate?.toLowerCase())
            &&(isUtilAllSelected(filterSelectedUtil) ||
              filterSelectedUtil.toLowerCase()===dataGap.utility.toLowerCase())
            &&(isAreaAllSelected(filterSelectedArea) ||
              filterSelectedArea.toLowerCase()===dataGap.location.toLowerCase())
            &&(searchText.length === 0 || dataGap.electricityMPAN.toLowerCase().includes(searchText.toLowerCase()))
    )
  }
  return filteredMeterData
}

export function createDataGapsWithFiltersObject(owner, selectedConsumptionType, meterDataGaps, dataQualityHomeState) {
  const dataGapsWithFiltersLandlord={
    filterEstimate: null,
    filterSelectedUtil: null,
    filterSelectedArea: null,
    meterDataGaps: null,
  }

  dataGapsWithFiltersLandlord.filterEstimate=selectedConsumptionType
  dataGapsWithFiltersLandlord.meterDataGaps=meterDataGaps
  if(LANDLORD===owner){
    dataGapsWithFiltersLandlord.filterSelectedUtil= dataQualityHomeState.selectedUtilityLandlord.value
    dataGapsWithFiltersLandlord.filterSelectedArea= dataQualityHomeState.selectedAreaLandlord.value
  } else {
    dataGapsWithFiltersLandlord.filterSelectedUtil= dataQualityHomeState.selectedUtilityTenant.value
    dataGapsWithFiltersLandlord.filterSelectedArea= dataQualityHomeState.selectedAreaTenant.value
  }
  return dataGapsWithFiltersLandlord
}

export function preloadMeterDataGaps(dqOverviewDataForLandlord, dqOverviewDataForTenant, missingData, dataQualityHomeState, updateDataQualityHomeState) {
  // The page should show preloaded to Missing but if Missing = 0, preload to Actual, if Actual = 0, preload to estimated.
  let meterDataGapsLandlord = getDataGapLandlord(missingData)
  let meterDataGapsTenant = getDataGapTenant(missingData)
  // Filter Landlord Data Gaps.
  if(dqOverviewDataForLandlord.data && meterDataGapsLandlord  && meterDataGapsLandlord.length>0){
    const dataGapsWithFiltersObjectLandlord=createDataGapsWithFiltersObject(
      LANDLORD,
      dataQualityHomeState.selectedCategoryLandlord.value,
      meterDataGapsLandlord,
      dataQualityHomeState
    )
    meterDataGapsLandlord = filterMeterDataGaps({...dataGapsWithFiltersObjectLandlord})
    updateDataQualityHomeState({dataGapLandlordSorted: meterDataGapsLandlord})
  }
  // Filter Tenant Data Gaps.
  if(dqOverviewDataForTenant.data && meterDataGapsTenant && meterDataGapsTenant.length>0){
    const dataGapsWithFiltersObjectTenant=createDataGapsWithFiltersObject(
      TENANT,
      dataQualityHomeState.selectedCategoryTenant.value,
      meterDataGapsTenant,
      dataQualityHomeState
    )
    meterDataGapsTenant = filterMeterDataGaps({...dataGapsWithFiltersObjectTenant})
    updateDataQualityHomeState({dataGapTenantSorted: meterDataGapsTenant})
  }
}

// Prepare menu-items for Meter Data Gap
export function prepareMeterDataGapMenuItems(data, updateDataQualityHomeState) {
  if (data?.availableAreas && data?.availableUtilitiesLandlord && data?.availableUtilitiesTenant) {
    let utilItemsLandlord = data.availableUtilitiesLandlord.map((util) => {
      return {label: I18n('t_' + util.toLowerCase()), value: util}
    })

    let utilItemsTenant = data.availableUtilitiesTenant.map((util) => {
      return {label: I18n('t_' + util.toLowerCase()), value: util}
    })

    let areaItems = data.availableAreas.map((area) => {
      return {label: I18n('t_' + area.toLowerCase()), value: area}
    })

    updateDataQualityHomeState({
      utilityListsLandlordMeterDataGap: [utilityListsDefault, ...utilItemsLandlord],
      utilityListsTenantMeterDataGap: [utilityListsDefault, ...utilItemsTenant],
      areaListsMeterDataGap: [areaListsDefault, ...areaItems],
    })
  }
}

export function updateMeterDataParams(newUtil, newArea, assetId, updateDataQualityHomeState, timePeriodState, time){
  updateDataQualityHomeState({ variablesMeterData: [
    'meterData', 
    assetId, 
    newUtil.value,
    newArea.value, 
    time, 
    timePeriodState.timeNoOfMonthsPeriod, 
    timePeriodState.timeEndDatePeriod
  ]})
}

export function updateMeterDataUtility(newUtilObj, updateDataQualityHomeState){
  updateDataQualityHomeState({ selectedUtility: newUtilObj })
}

export function updateMeterDataArea(newAreaObj, updateDataQualityHomeState){
  updateDataQualityHomeState({ selectedArea: newAreaObj })
}

export const getMeterModalParams = (assetId, time, timePeriodState) => {
  return {
    assetId: assetId,
    period: time,
    noOfMonths: timePeriodState.timeNoOfMonthsPeriod,
    endMonth: timePeriodState.timeEndDatePeriod,
  }
}

export const handleURLValues = (list, selectedValue) => {
  return list.some(element => element.value === selectedValue) ? selectedValue : MENU_ITEM_ALL
}

export const handleDatePicker = (datePickerValue, updateDatePickerQuery, updateTimePeriodState, timePeriodState) => {
  datePickerQuery(periodOptions, datePickerValue, updateDatePickerQuery)

  if (datePickerValue.timePeriodSelected === PERIOD_TYPES.CUSTOM) {
    const { noOfMonths, endYear, endMonth } = datePickerValue
    updateTimePeriodState({
      timeNoOfMonthsPeriod: noOfMonths,
      timeEndDatePeriod: createEndMonthParam(endYear, endMonth),
    })
  }
  updateTimePeriodState({ timePeriod: timePeriodState.timePeriod })
}

export const getDQInitialState = (queryValues) => {
  const timePeriod = getInitialTimePeriod(queryValues)
  const pickerValue = getInitialPickerValue(queryValues)
  const timeNoOfMonthsPeriod = pickerValue?.pickerValueMonths
  const timeEndDatePeriod = createEndMonthParam(pickerValue.pickerValueEndYear, pickerValue.pickerValueEndMonth)

  return {
    timePeriod,
    timeNoOfMonthsPeriod,
    timeEndDatePeriod,
    pickerValue,
    customDateSummary: false,
    metersSelected: [],
    metersSelectedLandlord: [],
    metersSelectedTenant: [],
    dqAction: null,
    searchMeterNameLandlord: '',
    searchMeterNameTenant: '',
    tabIndex: queryValues.get('view') === 'meter' ? 2 : 1,
    categorySelectedLandlord: queryValues.get('landlordCategory') === null ? MENU_ITEM_ALL : queryValues.get('landlordCategory'),
    utilitySelectedLandlord: queryValues.get('landlordUtility') === null ? MENU_ITEM_ALL : queryValues.get('landlordUtility'),
    areaSelectedLandlord: queryValues.get('landlordArea') === null ? MENU_ITEM_ALL :  queryValues.get('landlordArea'),
    categorySelectedTenant: queryValues.get('tenantCategory') === null ? MENU_ITEM_ALL : queryValues.get('tenantCategory'),
    utilitySelectedTenant: queryValues.get('tenantUtility') === null ? MENU_ITEM_ALL : queryValues.get('tenantUtility'),
    areaSelectedTenant: queryValues.get('tenantArea') === null ? MENU_ITEM_ALL : queryValues.get('tenantArea'),
    meterUtility: queryValues.get('meterUtility') === null ? MENU_ITEM_ALL : queryValues.get('meterUtility'),
    meterArea: queryValues.get('meterArea') === null ? MENU_ITEM_ALL : queryValues.get('meterArea'),
    meterSearchValue: '',
    tenantFirst: 0,
    landlordFirst: 0,
    tenantRows: 5,
    landlordRows: 5,
  }
}

export function isLandlord(responsibility) {
  return responsibility === LANDLORD
}

export function getCoverageChartData(coverageData) {

  if (!coverageData) {
    return {datasets: []}
  }

  const { areaCovered, areaNotCovered } = coverageData

  return  {
    datasets: [
      {
        data: [areaCovered, areaNotCovered],
        backgroundColor: [CSS_VARIABLES.blue, CSS_VARIABLES.forest_20],
        borderWidth: 0
      }
    ]
  }
}

export function getCoverageChartOptions() {

  return {
    maintainAspectRatio: false,
    cutout: '70%',
    responsive: true,
    animation: false,
    plugins: {
      legend: {display: false},
      datalabels: {display: false},
      tooltip: {enabled: false}
    }
  }
}

export function getCompletenessChartData(completenessData) {

  const {
    actualPercentage,
    supplierEstimatePercentage,
    calculatedEstimatePercentage,
    missingPercentage
  } = completenessData

  const chartData = {
    labels: [''],
    datasets: []
  }

  const commonChartConfigs = {
    borderRadius: 0,
    borderSkipped: false,
    borderWidth: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    },
    borderColor: CSS_VARIABLES.white,
    barThickness: 20
  }

  if (actualPercentage > 0) {
    chartData.datasets.push(
      {
        label: 'actual',
        data: [actualPercentage],
        backgroundColor: CSS_VARIABLES.forest,
        hoverBackgroundColor: CSS_VARIABLES.forest,
        ...commonChartConfigs
      }
    )
  }
  if (supplierEstimatePercentage > 0) {
    chartData.datasets.push(
      {
        label: 'supplierEstimate',
        data: [supplierEstimatePercentage],
        backgroundColor: CSS_VARIABLES.green_light,
        hoverBackgroundColor: CSS_VARIABLES.green_light,
        ...commonChartConfigs
      }
    )
  }
  if (calculatedEstimatePercentage > 0) {
    chartData.datasets.push(
      {
        label: 'calculatedEstimate',
        data: [calculatedEstimatePercentage],
        backgroundColor: CSS_VARIABLES.sand,
        hoverBackgroundColor: CSS_VARIABLES.sand,
        ...commonChartConfigs
      }
    )
  }
  if (missingPercentage > 0) {
    chartData.datasets.push(
      {
        label: 'missing',
        data: [missingPercentage],
        backgroundColor: CSS_VARIABLES.nature,
        hoverBackgroundColor: CSS_VARIABLES.nature,
        ...commonChartConfigs
      }
    )
  }

  return chartData
}

const commonChartOptions={
  stacked: true,
  display: false,
  ticks: {display: false},
  grid: {display: false},
  border: {display: false}
}

export function getCompletenessChartOptions(yMax, tooltipConfig) {
  const tooltipDefaultConfig = tooltipConfig ?? {enabled: false}
  return {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    layout: {padding: 0},
    scales: {
      x: {max: yMax, ...commonChartOptions},
      y: {stepSize: 0, ...commonChartOptions}
    },
    plugins: {
      tooltip: tooltipDefaultConfig,
      legend: {display: false,},
      datalabels: {display: false}
    },
    onHover: (event, chartElement) => {
      if (chartElement.length > 0) {
        event.native.target.style.cursor = 'pointer'
      }
    }
  }
}

export function getCompletenessLegends({actualPercentage, supplierEstimatePercentage, calculatedEstimatePercentage, missingPercentage}, selectedLanguage) {

  const iconCommonProps = {
    id: 'square_solid',
    size: 10
  }

  const completenessChartColours = {
    actualPercentage: 'forest',
    supplierEstimatePercentage: 'green_light',
    calculatedEstimatePercentage: 'sand',
    missingPercentage: 'orange'
  }

  return (
    <>
      {actualPercentage > 0 &&
            <>
              <Icon colour={completenessChartColours.actualPercentage} {...iconCommonProps}  />
              <Text content={`${formatNumWOneDecimal(selectedLanguage, actualPercentage)}% ${getLocalizedValue(selectedLanguage, 't_actual_data_legend')}`} size="xs" />
            </>
      }

      {supplierEstimatePercentage > 0 &&
            <>
              <Icon colour={completenessChartColours.supplierEstimatePercentage} {...iconCommonProps} />
              <Text content={`${formatNumWOneDecimal(selectedLanguage, supplierEstimatePercentage)}% ${getLocalizedValue(selectedLanguage, 't_supplier_estimate')}`} size="xs" />
            </>
      }

      {calculatedEstimatePercentage > 0 &&
            <>
              <Icon colour={completenessChartColours.calculatedEstimatePercentage} {...iconCommonProps} />
              <Text content={`${formatNumWOneDecimal(selectedLanguage, calculatedEstimatePercentage)}% ${getLocalizedValue(selectedLanguage, 't_calculated_estimate')}`} size="xs" />
            </>
      }

      {missingPercentage > 0 &&
            <>
              <Icon colour={completenessChartColours.missingPercentage} {...iconCommonProps} />
              <Text content={`${formatNumWOneDecimal(selectedLanguage, missingPercentage)}% ${getLocalizedValue(selectedLanguage, 't_missing_tc')}`} size="xs" />
            </>
      }
    </>
  )
}

export const createMissingDataReqObjectV2 = (missingData) => {
  return missingData.map(meter => {
    if(meter.meterId && meter.dateRange && !isEmptyObject(meter.dateRange)) {
      return {
        meterID: meter.meterId,
        dateStart: meter.dateRange.start,
        dateEnd: meter.dateRange.end
      }
    }
  })
}